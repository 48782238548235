import {
    Box,
    Button,
    Container,
    Divider,
    Grid2,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { applyToOffer } from "../../redux/actions/userActions";
import { LoadingButton } from "@mui/lab";
import parse from "html-react-parser";
import PubBanner from "../../components/pub/Banner";
import LatestNews from "../../components/includes/LatestNews";
import PubSquare from "../../components/pub/Square";

const mapState = ({ user }) => ({
    loading: user.loading,
});

const OfferDetails = () => {
    const params = useParams();
    const [offer, setOffer] = useState({});
    const [user, setUser] = useState({});
    const dispatch = useDispatch();
    const currentUser = localStorage.getItem("userID");
    const { loading } = useSelector(mapState);
    const navigate = useNavigate();

    useEffect(() => {
        const docRef = doc(db, "offers", params && params.offerID);
        const userDocRef = currentUser && doc(db, "users", currentUser);

        const unsubscribe = onSnapshot(
            docRef,
            (snapshot) => {
                if (snapshot.data()) {
                    setOffer({ ...snapshot.data(), id: snapshot.id });
                }
            },
            (err) => {
                console.log(err.message);
            }
        );
        const getUser =
            currentUser &&
            onSnapshot(
                userDocRef,
                (snapshot) => {
                    if (snapshot.data()) {
                        setUser({ ...snapshot.data(), id: snapshot.id });
                    }
                },
                (err) => {
                    console.log(err.message);
                }
            );

        return () => {
            unsubscribe();
            currentUser && getUser();
        };
    }, []);

    const existUser = (users, userID) =>
        userID && users && users.find((user) => user === userID);

    const handleApply = () => {
        currentUser
            ? dispatch(applyToOffer(offer.id, user))
            : navigate("/se-connecter");
    };

    return (
        <Box>
            {/* <PubBanner /> */}
            <Container sx={{ py: 2 }}>
                <Grid2 container direction={'row'} spacing={2}>
                    <Grid2 item size={{ xs: 12, md: 8 }} container>
                        {/* <Grid2 sx={{ px: 3 }} item size={12}>
                        <Typography textAlign={"center"}>
                            <img src="/images/logo.png" width={200} />
                        </Typography>
                    </Grid2> */}
                        <Grid2 item size={12}>
                            <Typography variant="h3">{offer && offer.poste}</Typography>
                            <Typography color={"text.secondary"}>
                                {offer && offer.business}
                            </Typography>
                            <Box>
                                <Button
                                    sx={{ color: "black" }}
                                    startIcon={<PlaceIcon color="primary" />}
                                >
                                    {offer && offer.city}
                                </Button>
                                <Button
                                    sx={{ color: "black", mx: 1 }}
                                    startIcon={<DescriptionIcon color="primary" />}
                                >
                                    {offer && offer.contratType}
                                </Button>
                                <Button
                                    sx={{ color: "black" }}
                                    startIcon={<HistoryIcon color="primary" />}
                                >
                                    {offer && offer.workTime}
                                </Button>
                            </Box>
                        </Grid2>
                        <Grid2 item size={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid2>
                        <Grid2 item size={12}>
                            <Typography textAlign={"justify"}>
                                {offer && offer.description && parse(offer && offer.description)}
                            </Typography>

                            <Typography color={"text.secondary"}>
                                {offer && offer.business}
                            </Typography>
                        </Grid2>
                        <Grid2 item size={12}>
                            <Typography textAlign={"center"}>
                                {existUser(offer.userIDs, currentUser && currentUser) ? (
                                    <Button variant="outlined" sx={{ px: 6, borderRadius: 10 }}>
                                        Vous avez postulé à cette offre
                                    </Button>
                                ) : (
                                    <LoadingButton
                                        loading={loading}
                                        sx={{ px: 6, borderRadius: 10 }}
                                        variant="contained"
                                        onClick={handleApply}
                                    >
                                        Postuler
                                    </LoadingButton>
                                )}
                            </Typography>
                        </Grid2>
                    </Grid2>

                    <Grid2 item size={{ xs: 12, md: 4 }}>
                        <PubSquare />
                    </Grid2>

                    {/* <LatestNews /> */}

                </Grid2>

            </Container>
        </Box>
    );
};

export default OfferDetails;
