import { Box, Button, Container, Grid2, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import parse from 'html-react-parser'
import { rubriqueColor } from "../../functions";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HomeBanner = ({ articles }) => {
    const theme = useTheme();
    var settings = {
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <Box>
            <Slider {...settings} >
                {
                    articles.slice(0, 5).map(article => (
                        <Grid2
                            container
                            sx={{
                                overflow: "hidden",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundImage: `linear-gradient(
                                to bottom,
                                ${alpha(theme.palette.primary.main, 0.7)},
                                ${alpha(theme.palette.primary.main, 0.5)}
                                ), url('/images/cover.JPG') `,
                                color: 'white',
                                py: 6,
                                height: 500

                            }}
                            spacing={2}
                        >

                            <Container>
                                <Grid2
                                    container
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    spacing={2}
                                >
                                    <Grid2 item size={{ xs: 12, md: 6 }}>
                                        <Container>
                                            <Typography textAlign={'center'} sx={{ backgroundColor: rubriqueColor(article.rubrique), width: '30%' }} >{article.rubrique} </Typography>
                                            <Typography sx={{ fontSize: { xs: 25, md: 30 } }} textAlign={"left"} fontWeight={'700'} variant="h4" component={'h2'}>
                                                {article.title.slice(0, 500)}
                                            </Typography>
                                            <Typography sx={{ fontSize: { xs: 13, md: 17 } }}>{parse(article.content.slice(0, 200))}</Typography>
                                            <Box>
                                                <Button endIcon={<ArrowForwardIcon />} sx={{ color: 'white' }}>En savoir plus</Button>
                                            </Box>
                                        </Container>
                                    </Grid2>
                                    <Grid2
                                        container
                                        direction={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        sx={{
                                            display: { xs: "none", md: "flex" }
                                        }}
                                        item size={{ md: 6 }}>
                                        <img src={article.images ? article.images[0] : article.image} width={'60%'} />
                                    </Grid2>
                                </Grid2>
                            </Container>
                        </Grid2>
                    ))
                }
            </Slider>
        </Box>
    );
};

export default HomeBanner;
