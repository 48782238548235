import {
    Box,
    Container,
    Divider,
    Grid2,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import parse from "html-react-parser";
import moment from "moment";
import 'moment/locale/fr'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { BASE_URL } from "../../data";
import ImageSlider from "../../components/includes/ImageSlider";
import MoreArticles from "../../components/article/More";
import NewsletterForm from "../../components/newsletter/Form";
import { rubriqueColor } from "../../functions";
import LatestNews from "../../components/includes/LatestNews";
import Videos from "../../components/includes/Videos";
import BouncingLogo from "../../components/includes/BouncingLogo";
import Seo from "../../components/includes/Seo";
import {
    collection,
    query,
    where,
    doc,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import PubBanner from "../../components/pub/Banner";

const ArticleDetails = () => {
    const params = useParams();
    const [article, setArticle] = useState({});
    const [videos, setVideos] = useState([]);
    const theme = useTheme()
    const [windowHeight, setWindowHeight] = useState(500); // État pour la hauteur de la fenêtre

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const docRef = doc(db, "articles", params.articleID);
        const videosRef = collection(db, "videos");

        const q = query(
            videosRef,
            orderBy("createdAt", "desc")
        );


        const unsubscribe = onSnapshot(
            docRef,
            (snapshot) => {
                if (snapshot.data()) {
                    setArticle({ ...snapshot.data(), id: snapshot.id });
                }
            },
            (err) => {
                console.log(err.message);
            }
        );

        const getVideos = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setVideos(content);
            }
        });

        return () => {
            unsubscribe();
            getVideos()
        };
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
        }
    }, []);


    return (
        <Box>
            <Seo
                title={article && article.title}
                url={BASE_URL + 'articles/' + article && article.id}
                // image={article && article.images[0]}
                description={article && article.content && parse(article && article.content).slice(0, 200)}
            />

            {/* <PubBanner /> */}

            {article && article.title ? (
                <Container sx={{ py: 3 }}>
                    <Grid2 container spacing={2}>
                        <Grid2 item size={{ xs: 12, md: 8 }} container spacing={2}>
                            <Grid2 item size={12} container sx={{ p: 3 }} spacing={2}>
                                <Typography fontSize={14} color={'white'} sx={{ my: 1, backgroundColor: rubriqueColor(article.rubrique), width: '25%', textAlign: 'center' }} fontWeight={'500'}>{article.rubrique}</Typography>
                                <Grid2 item size={12}>
                                    <Typography variant="h3">{article && article.title}</Typography>
                                    <Typography color={'text.secondary'} variant="subtitle">Par {article && article.author}</Typography>
                                </Grid2>
                                {article && article.images && article.images.length > 1 ?
                                    <ImageSlider images={article && article.images} />
                                    : (
                                        <Grid2 sx={{
                                            px: 3,
                                            p: 5, height: 500,
                                            mt: 2,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundImage: `linear-gradient(
                                to bottom,
                                ${alpha(theme.palette.primary.main, 0.0)},
                                ${alpha(theme.palette.primary.main, 0.0)}
                                ), url(${article && article.images ? article.images[0] : article.image}) `,
                                        }}
                                            item
                                            size={12}
                                        >

                                        </Grid2>
                                    )}
                                <Grid2 sx={{ mt: 1 }} item size={12}>
                                    <Typography>{moment(article.createdAt).calendar()}</Typography>
                                </Grid2>
                                <Grid2 item size={12}>
                                    <EmailShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <EmailIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </EmailShareButton>
                                    <TwitterShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <TwitterIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </TwitterShareButton>
                                    <FacebookShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <FacebookIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </FacebookShareButton>
                                    <WhatsappShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <WhatsappIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </WhatsappShareButton>
                                    <LinkedinShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <LinkedinIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </LinkedinShareButton>
                                    <TelegramShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <TelegramIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </TelegramShareButton>
                                    <FacebookMessengerShareButton url={BASE_URL + 'articles/' + article.id}>
                                        <FacebookMessengerIcon size={32} style={{ marginRight: 3 }} round={true} />
                                    </FacebookMessengerShareButton>
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 3 }} />
                            <Grid2 item size={12} container spacing={2}>
                                <Grid2 item size={12}>
                                    <Typography textAlign={"justify"}>
                                        {article.content && parse(article.content)}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                        </Grid2>

                        <LatestNews />

                    </Grid2>
                    <Videos videos={videos && videos} />
                    <MoreArticles rubrique={article && article.rubrique} />
                    <NewsletterForm />
                </Container>
            ) : (
                <Box sx={{
                    height: windowHeight,
                    flex: 1,
                    justifyContent: 'center',
                    alignContent: 'center'

                }}>
                    <BouncingLogo />
                </Box>

            )}
        </Box>
    );
};

export default ArticleDetails;
