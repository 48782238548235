import { Box, Button, Container, Divider, Grid2, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { db } from '../../firebase';

const LatestNews = ({ data }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const [articles, setArticles] = useState([]);


    useEffect(() => {
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            orderBy("createdAt", "desc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });
        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);
    return (
        <Grid2 container item size={{ md: 4, xs: 12 }}>
            {/* <Grid2 size={12}>
                <Typography>
                    <img src='/images/kobo-mobile.png' width={'100%'} alt='Kobo Art Festival' />
                </Typography>
            </Grid2> */}
            <Box sx={{ backgroundColor: '#eee' }}>
                <Container sx={{ py: 3 }} >
                    <Typography sx={{ my: 2 }} fontWeight={'700'} textAlign={'center'} fontSize={20} variant='h5'>Les dernières actualités</Typography>
                    <List dense>
                        {articles && articles.slice(0, 8).map((article) => (
                            <Box>
                                <ListItemButton onClick={() => navigate('articles/' + article.id)}>
                                    <ListItemIcon>
                                        <img src={article.images ? article.images[0] : article.image} width={70} />
                                    </ListItemIcon>
                                    <ListItem>
                                        <ListItemText primary={article.title} secondary={moment(article.createdAt).calendar()} />
                                    </ListItem>

                                </ListItemButton>
                                <Divider />
                            </Box>
                        ))}
                    </List>
                    <Box>
                        <Button component='a' href='/articles' endIcon={<ArrowForwardIcon />} sx={{ color: theme.palette.primary.main }}>Voir plus d'actualités</Button>
                    </Box>
                </Container>
            </Box>
        </Grid2>
    )
}

export default LatestNews