import { Box, Container, Grid2, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import ALaUne from '../../components/home/News';
import HomeCulture from '../../components/home/Culture';
import HomeSport from '../../components/home/Sport';
import NewsletterForm from '../../components/newsletter/Form';
import HomeBanner from '../../components/home/Banner';
import Videos from '../../components/includes/Videos';
import BouncingLogo from '../../components/includes/BouncingLogo';
import HomeMuzik from '../../components/home/Muzik';
import PubBanner from '../../components/pub/Banner';



const Home = () => {
    const [articles, setArticles] = useState([]);
    const [videos, setVideos] = useState([])
    const [windowHeight, setWindowHeight] = useState(500)

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const colRef = collection(db, "articles");
        const colRefVideos = collection(db, "videos");
        const q = query(
            colRef,
            orderBy("createdAt", "desc")
        );
        const qVideos = query(
            colRefVideos,
            orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });

        const allVideos = onSnapshot(qVideos, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setVideos(content);
            }
        });

        // Unsubscribe from events when no longer in use
        return () => {
            unsubscribe()
            allVideos()
        };
    }, []);

    useEffect(() => {
        // Mettez à jour la hauteur de la fenêtre uniquement côté client
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
        }
    }, []);

    return (
        <div>
            {articles && articles.length > 0 ? (
                <Box>

                    {/* <PubBanner /> */}

                    <HomeBanner articles={articles && articles} />

                    <ALaUne />

                    <Videos videos={videos && videos} />

                    <HomeMuzik />

                    <HomeCulture />

                    <HomeSport />

                    <NewsletterForm />
                </Box>
            ) : (
                <Box sx={{
                    height: windowHeight,
                    flex: 1,
                    justifyContent: 'center',
                    alignContent: 'center'

                }}>
                    <BouncingLogo />
                </Box>
            )}
        </div>
    )
}

export default Home