import { Box, Container, Grid2, Pagination, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchBar from '../../components/includes/SearchBar'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';
import ArticleItem from '../../components/article/Item';
import NewsletterForm from '../../components/newsletter/Form';
import LatestNews from '../../components/includes/LatestNews';
import Seo from '../../components/includes/Seo';
import { BASE_URL } from '../../data';
import BouncingLogo from '../../components/includes/BouncingLogo';
import PubBanner from '../../components/pub/Banner';

const Articles = () => {
    const [articles, setArticles] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const itemsPerPage = 12;
    const [itemOffset, setItemOffset] = useState(0);
    const [windowHeight, setWindowHeight] = useState(500); // État pour la hauteur de la fenêtre


    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
                setData(content)
            }
        });

        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, [articles]);

    const searchFilter = (text) => {
        const newData =
            articles &&
            articles.filter((item) => {
                const itemData = item.title
                    ? item.title.toUpperCase()
                    : "".toUpperCase();
                const textData = text.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
        setSearch(text);
        setData(newData);
        setItemOffset(0);
    };

    const handlePageClick = (event, value) => {
        const newOffset = (value * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        // Mettez à jour la hauteur de la fenêtre uniquement côté client
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
        }
    }, []);


    return (
        <Box>
            <Seo
                title={"Toute l'actualité congolaise ici"}
                description={"Toute l'actualité congolaise ici"}
                url={BASE_URL + '/articles'}
            />
            {/* <PubBanner /> */}
            {articles && articles.length > 0 ? <Container sx={{ py: 5 }}>
                <Grid2 spacing={2} container>
                    <Grid2 item size={{ xs: 12, md: 8 }} container spacing={2}>
                        <Grid2 item size={12}>
                            <Typography sx={{ my: 2 }} variant='h3'>Toute l'actualité congolaise ici!</Typography>
                            <SearchBar searchFilter={searchFilter} placeholder={'Rechercher un article...'} />
                        </Grid2>
                        <Grid2 container spacing={2} item size={12}>
                            {currentItems && currentItems.map((article) => (
                                <Grid2 item size={{ xs: 6, md: 4 }}>
                                    <ArticleItem article={article} />
                                </Grid2>
                            ))}

                        </Grid2>
                        <Grid2
                            container
                            direction={"row"}
                            justifyContent={"center"}
                            sx={{ mt: 2 }}
                            item
                            size={12}
                        >
                            <Pagination
                                variant="outlined"
                                color="primary"
                                onChange={handlePageClick}
                                count={pageCount}
                            />
                        </Grid2>
                    </Grid2>

                    <LatestNews />

                </Grid2>
                <NewsletterForm />
            </Container> : <Box sx={{
                height: windowHeight,
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center'

            }}>
                <BouncingLogo />
            </Box>}
        </Box>
    )
}

export default Articles